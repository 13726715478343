/*
  FOR AODA COMPLIANCE, TEST WITH THE FOLLOWING UNSET:

  * {
    line-height: 1.5!important;
    letter-spacing:.12em!important;
    word-spacing: .16em !important;
  }
  p {
    margin-bottom: 2em!important;
  } 
*/

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 100%;
  height: 100%;
}

/* @media only screen and (max-width: 600px) {
  h1 {
    font-size: 2rem !important;
  }
  h2 {
    font-size: 1.75rem !important;
  }
  h3 {
    font-size: 1.625rem !important;
  }
  h4 {
    font-size: 1.375rem !important;
  }
  h5 {
    font-size: 1.125rem !important;
  }
  h6 {
    font-size: 2rem !important;
  }
} */

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.normal-table {
  /* width: 100%; */
  text-align: left;
}

.normal-table th,
.normal-table td {
  padding: 10px;
  vertical-align: top;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.grouped-table {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  /* font-size: 0.8rem; */
}

.share-price-statistics-table .cellbordertopbottom {
  white-space: nowrap;
}

.grouped-table thead {
  background-color: #cacaca;
  vertical-align: bottom;
}

.grouped-table th,
.grouped-table td {
  padding: 10px;
}

.black-text {
  color: black;
  text-decoration: underline;
}

.data-source-list input {
  margin-right: 10px;
}

.data-source-list {
  margin-left: 0;
  padding-left: 0;
}

.data-source-list li {
  margin-left: 0;
  margin-bottom: 10px;
}

* {
  box-sizing: border-box;
  font-family: "Figtree", sans-serif;
  line-height: 1.2;
}

a {
  color: black;
}

tr.tabletitle {
  font-weight: bold;
}

table.subsidiaries dt {
  padding: 2px;
  line-height: 1.4;
}

table.subsidiaries h4 {
  padding: 5px;
  margin: 0;
}

.icsmallerbold,
.icsmallbold,
.cellbold {
  font-weight: bold;
}

.full-width {
  width: 100%;
}

/* FP Adivsor Legacy Indentation Levels */
.indent1 {
  text-indent: -12pt;
  margin-left: 12pt;
}
.indent2 {
  text-indent: -12pt;
  margin-left: 24pt;
}
.indent3 {
  text-indent: -12pt;
  margin-left: 36pt;
}
.indent4 {
  text-indent: -12pt;
  margin-left: 48pt;
}
.indent5 {
  text-indent: -12pt;
  margin-left: 60pt;
}
.indent6 {
  text-indent: -12pt;
  margin-left: 72pt;
}
.indent7 {
  text-indent: -12pt;
  margin-left: 84pt;
}
.indent8 {
  text-indent: -12pt;
  margin-left: 96pt;
}
.indent9 {
  text-indent: -12pt;
  margin-left: 108pt;
}
.indent10 {
  text-indent: -12pt;
  margin-left: 120pt;
}
.indent11 {
  text-indent: -12pt;
  margin-left: 132pt;
}
.indent12 {
  text-indent: -12pt;
  margin-left: 144pt;
}
.indent13 {
  text-indent: -12pt;
  margin-left: 156pt;
}
.indent14 {
  text-indent: -12pt;
  margin-left: 168pt;
}
.indent15 {
  text-indent: -12pt;
  margin-left: 180pt;
}
.indent16 {
  text-indent: -12pt;
  margin-left: 192pt;
}
.indent17 {
  text-indent: -12pt;
  margin-left: 204pt;
}
.indent18 {
  text-indent: -12pt;
  margin-left: 216pt;
}
.indent19 {
  text-indent: -12pt;
  margin-left: 228pt;
}
.indent20 {
  text-indent: -12pt;
  margin-left: 240pt;
}
/* (END) FP Adivsor Legacy Indentation Levels */

.predecessor-defunct-info .tabletitle > td {
  padding-top: 6px;
  font-weight: bold;
  font-size: 1.4rem;
}

form select {
  margin-top: 5px;
  padding: 8px;
  width: 341px;
  max-width: 100%;
  outline: none;
  border: 1px solid #767676;
  border-radius: 3px;
  font-size: 1rem;
}
 
form .fin-search {
  text-align: center;
  max-width: 100%;
}

form .fin-search .input-cell.sm {
  width: 75px;
  min-width: 75px;
  max-width: 75px;
}

form .fin-search .input-cell.sm select,
form .fin-search .input-cell.sm input {
  width: 73px;
  min-width: 73px;
  max-width: 73px;
  margin: 0;
}

form .fin-search .input-cell {
  width: 150px;
  max-width: 150px;
}

form .fin-search .input-cell select, form .fin-search .input-cell input {
  width: 148px;
  max-width: 148px;
  min-width: 148px;
  font-size: 0.7rem;
  margin: 0;
}

form input:valid:focus, form textarea:valid:focus, form select:valid:focus,
form input:focus, form textarea:focus, form select:focus {
  border: 2px solid #24bf;
  -webkit-box-shadow: none;
}

input[type='checkbox'] {
  width: 20px;
  height: 20px;
  padding: 0;
  margin:0;
  vertical-align: bottom;
  position: relative;
  top: -1px;
  *overflow: hidden;
}

input[type="checkbox"] ~ label {
  padding-left: 15px;
  text-indent: -15px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 20px;
}

.hide {
  display: none;
}

/** for modals **/
.ReactModalPortal,
.ReactModalPortal > * {
  z-index: 9999;
}

/** for calender underline **/
.calendar .rdrDayToday .rdrDayNumber span:after {
  background: none !important;
}

.rdrWeekDay {
  color: black !important;
}

ul.experiences {
  position: relative;
  padding-left: 20px;
}

ul.experiences li:not(:last-child):before {
  content: "";
  position: absolute;
  left:6px;
  margin-top: 20px;
  border-left: 1px solid #CACACA;
  height: 20px;
  width: 1px;
}

/** Link Search Card **/
.linkSearch {
  color: #2E4EBF;
  font-weight: 600;
  vertical-align: 'middle';
  font-size: x-large;
}
.linkArrow {
  color: #2E4EBF;
  font-weight: 600;
  vertical-align: 'middle';
  font-size: x-large;
}
.linkSearchCard:hover{
  text-decoration: underline;
  color: #2E4EBF;
}
.linkSearchCard:focus{
  text-decoration: underline;
  color: #2E4EBF;
}
.col-third {
  width: 100%;
  padding-right: 24px;
  display: inline-block;
  margin-bottom: 8px;
}

/** Link email **/
.linkEmail {
  color: #2E4EBF;
  text-decoration: none;
}
.linkEmail:hover{
  text-decoration: underline;
}
.linkEmail:focus{
  text-decoration: underline;
}


@media only screen and (min-width: 576px) {
  .col-third {
    width: 50%;
  }
}
@media only screen and (min-width: 768px) {
  .col-third {
    width: 33%;
  }
}


@media print {
  .hide-print{
    display: none;
  }
}
