.hep--result--btn--sec {
	display: flex;
	align-items: center;
	justify-content: center;
  .hep--result--btn {
    border: 1px solid #2e4ebf;
    color: #2e4ebf;
    font-weight: bold;
    font-size: 18px;
    background-color: transparent;
    &:hover, &:focus {
      border: 1px solid #2a3665;
      color: #2a3665;
      background-color: transparent;
    }
  }
}
