/* DEFAULT COLOR VARIABLES */
/* DEFAULT SIZING VARIABLES */
/* DEFAULT CLASS VARIABLE */
/* STYLES */
.d-flex {
  display: flex;
}
.switch.switch--default > .switch-toggle {
  margin-left: 20px;
  height: 22px;
  width: 42px;
  cursor: pointer !important;
  user-select: none !important;
  position: relative !important;
  display: inline-block;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  content: "";
  left: 0;
  position: absolute !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before,
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
  height: inherit;
  width: inherit;
  border-radius: 16px;
  will-change: background;
  transition: background 0.4s 0.3s ease-out;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::after,
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  top: 2px;
  height: 18px;
  width: 18px;
  border-radius: 14px;
  background: #ffffff !important;
  will-change: transform;
  transition: transform 0.4s ease-out;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::before {
  background: #2e4ebf !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--on::after {
  transform: translateX(22px);
}
.switch.switch--default > .switch-toggle.switch-toggle--off::before {
  background: #666666 !important;
}
.switch.switch--default > .switch-toggle.switch-toggle--off::after {
  transform: translateX(2px);
}
