.selected--criteria--sec{
	.selected--criteria--title{
		font-size: 18px;
	}
	.selected--criteria--div{
		margin-top: 10px;
		display: flex;
		flex-wrap: wrap;
	.selected--criteria{
		background-color: #f5f5f5;
		padding: 10px;
		border-radius: 25px;
		width: fit-content;
		font-size: 16px;
		margin-right: 10px;
		margin-bottom: 10px;
		.selected--criteria--info{
			font-weight: 400;
			.selected--criteria--title{
				font-weight: 600;
			}
		}
	}
}
}