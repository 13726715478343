.help--center--back--text {
  h3 {
    color: #2e4ebf;
    cursor: pointer;
  }
}
.alphabeticcally--btn {
  button {
    background: #eaf0f6;
    border: 1px solid #cbd6e2;
    box-sizing: border-box;
    border-radius: 4px;
		color: #333333;
		font-size: 16px;
		font-weight: 600;
		padding: 9px 8px;
		margin-right: 8px;
		&:hover, &:focus {
			background: #eaf0f6;
			border: 1px solid #cbd6e2;
			color: #333333;
		}
  }
}
