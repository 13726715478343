table {
  width: 100%;
}

tr.tabletitle {
  background-color: #2E4EBF;
  color: white;
  text-align: center;
}

tr.tabletitle > td h3 {
  margin: 6px;
}

tr.tabletitle > td > a {
  font-size: 12px;
}

.factset--graph {
  text-align: 'center';
}
