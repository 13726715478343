.accordian--scroll--styling::-webkit-scrollbar {
  width: 10px;
}
.accordian--scroll--styling::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 100px;
}

.accordian--scroll--styling::-webkit-scrollbar-thumb {
	border-radius: 100px;
	border: 5px solid transparent;
	background-clip: content-box;
	background-color: #c6c5ca;
}