.button-print {
  background: none;
	text-decoration: none;
	align-items: center;
	justify-content: center;
	height: 30px;
	width: auto;
	cursor: pointer;
	border-radius: 4px;
	border: 1px solid #000;
  flex-direction: row;
}

.button-print:hover {
  background: #f3f4f6;
}

.button-print:focus {
  background: none;
  border: 2px solid #2e4ebf;
}

.button-icon {
  position: relative;
  top: 4px;
}

.button-text {
  position: relative;
  bottom: 4px;
  text-align: center;
}