button.read--btn--class{
	background-color: transparent;
	font-size: 16px;
	border: transparent;
	color: #000;
	text-decoration: underline;
	padding-right: 8px;
	&:hover{
		background-color: transparent;
		border: transparent;
		color: #000;
		text-decoration: underline;
	}
	&:after{
		border: solid black;
		border-width: 0 3px 3px 0;
		display: inline-block;
		padding: 3px;
		margin-left: 5px;
	}
}
.search--result--read--less--btn.cXMkpw::after{
	content: '';
	transform: rotate(45deg) !important;
	-webkit-transform: rotate(45deg) !important; 
}
.search--result--read--more--btn.cXMkpw::after{
	content: '';
	transform: rotate(-135deg) !important;
	-webkit-transform: rotate(-135deg) !important;
}
.no--email--block{
	display: none;
}