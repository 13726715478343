.button-hidden {
	width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	overflow: hidden;
	-webkit-box-orient: vertical;
	.hidden--div--styling{
    overflow: hidden;
		width: fit-content;
	}
}
.read--content--div--styling{
	display: flex;
	flex-wrap: wrap;
}
.button-show{
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}
.read--more--less--child--sec:not(.read--more--less--child--hidden--sec){
	max-height: 49px;
	overflow: hidden;
}