.clearstatus {
		position: relative;
    cursor: pointer;
    display: inline;
    top: -85px;
    left: 120px;
  }
.clearstatus-new-search {
	position: absolute;
	/* top: 112px; */
	bottom: 121px;
	left: 188px;
}
.clearstatus-lead-list {
	position: absolute;
	bottom: 150px;
	left: 200px;
}
.mna-accordion {
  border-bottom: 1px solid #EAEAEA;
  padding-top: 20px;
  padding-bottom: 16px;
}

.mna-col {
  width: 100%;
  padding-right: 24px;
  display: inline-block;
  @media (min-width: 768px) {
    width: 50%;
  }
}

.mna-col select {
  width: 100%;
  height: 39px;
  border-radius: 3px;
  padding-left: 5px;
}

.mna-row {
  margin-top: 18px;
  margin-bottom: 24px;
  display: inline-block;
}

.mna-section {
  margin-top: 0px;
  margin-right: 12px;
  margin-bottom: 36px;
}

.mna-section select {
  width: 100%;
}

.mna-accordions {
  margin-bottom: 24px;
}
