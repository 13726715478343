.setting--alert{
	position: fixed;
    z-index: 9999;
    inset: 16px;
    pointer-events: none;
		& > div{
			right: 0px !important;
			display: flex;
			position: fixed !important;
			transition: all 230ms cubic-bezier(0.21, 1.02, 0.73, 1) 0s;
			transform: translateY(0px);
			bottom: 70px;
			justify-content: center;
			max-width: 90%;
			@media only screen and (min-width: 576px) {
				bottom: 50px;
			}
			span{
				display: flex;
				flex-wrap: wrap;
				max-width: 270px;
				@media only screen and (min-width: 576px) {
					max-width: unset;
				}
			}
		}
}
.setting--btn.cHthiB{
		border-right: transparent !important;
	}