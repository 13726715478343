.icon{
    width: fit-content !important;
    max-width: inherit;
    @media only screen and (min-width: 576px) {
        // width: auto!important;
        max-width: unset;
    }
    .eyeIcon{
    position: relative;
    input{
        padding-right: 80px;
        overflow: hidden;
    }
    .text{
        position: absolute;
        top: 6px;
        right: 8px;
				display: flex;
				justify-content: center;
        align-items: center;
				span{
					line-height: normal;
					position: relative;
				}
				svg{
					position: unset;
					right: unset;
					margin-left: 4px;
					top: unset;
					display: flex;
					justify-content: center;
					align-items: center;
					path{
						height: 100%;
					}
				}
    }
		.sv{
			position: relative;
			top: 4px;
		}
}}
